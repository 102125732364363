import createCableSubscription from '../utils/createCableSubscription';
import insertHtml from '../utils/insertHtml';
import setRideCountHeader from '../utils/setRideCountHeader';
import setScheduledFilterCount from '../utils/setScheduledFilterCount';
import statusDropdownState from '../utils/statusDropdownState';

const SCHEDULED_PATH_NAME = '/dispatcher/scheduled';

// evaluate on use since these tables are not always present
const dispatcherScheduledRidesTable = () =>
  document.getElementById('dispatcher-scheduled-rides-table');
const reactRidesTable = () =>
  document.getElementById('dispatcher-scheduled-rides-table-react');

document.addEventListener('turbolinks:load', () => {
  if (reactRidesTable()) {
    return;
  }

  if (window.location.pathname === SCHEDULED_PATH_NAME) {
    if (document.getElementById('status-multi-select')) {
      const statusOpts = Array.from(
        document
          .getElementById('status-multi-select')
          .querySelectorAll('input'),
      );
      statusDropdownState.addListeners(statusOpts);

      const queryParams = window.location.search.split('=')[1];
      if (queryParams) {
        const urlSearchParams = new URLSearchParams(
          new URL(window.location).search,
        );
        const searchValues = Array.from(urlSearchParams.values());
        const searchKeys = Array.from(urlSearchParams.keys());

        if (
          searchValues &&
          searchKeys.filter((key) => key === 'ride_status[]')[0]
        ) {
          statusOpts.forEach((option) => {
            if (
              option.value ===
              searchValues.filter((value) => value === option.value)[0]
            ) {
              option.checked = true;
            } else {
              option.checked = false;
            }
          });
        } else {
          statusOpts.forEach((option) => {
            option.checked = true;
          });
        }
      }
    }
  }

  createCableSubscription(
    SCHEDULED_PATH_NAME,
    'RidesChannel',
    'dispatcherScheduledRides',
    {
      connected() {
        console.log('Connected Scheduled Rides');
      },

      disconnected() {
        console.log('Disconnected Scheduled Rides');
      },

      received(data) {
        if (reactRidesTable()) {
          return;
        }

        if (!dispatcherScheduledRidesTable()) {
          return;
        }

        /**
         * Two types of broadcast
         * A new ride broadcast
         * An updated ride broadcast
         * These can be noted by looking for the ride in the table
         */
        const ride = document.getElementById(`ride-${data.ride.id}`);

        // eslint-disable-next-line no-unused-expressions
        ride
          ? handleUpdatedRideBroadcast(data, ride)
          : handleNewRideBroadcast(data);
      },
    },
  );
});

var handleUpdatedRideBroadcast = (data, ride) => {
  // Remove element
  ride.remove();
  insertHtml.prepend(dispatcherScheduledRidesTable(), data.table_row);
};

var handleNewRideBroadcast = (data) => {
  /**
   * If the community table is empty we have to first replace the empty div with the table
   * Then we can prepend rides to the table & update the ride count header
   */
  setRideCountHeader();
  setScheduledFilterCount();
  const noRidesPlaceholder = document.getElementById('no-rides-placeholder');
  const mainCommunityContainer = document.getElementById(
    'main-community-container',
  );

  if (noRidesPlaceholder) {
    noRidesPlaceholder.remove();

    if (!mainCommunityContainer) {
      return;
    }

    mainCommunityContainer
      .querySelector('#patient-rides-table')
      .classList.remove('hide');
    insertHtml.prepend(dispatcherScheduledRidesTable(), data.table_row);
  } else {
    insertHtml.prepend(dispatcherScheduledRidesTable(), data.table_row);
  }
};
