var insertHtml = {
  append(elem, content) {
    elem.append(
      htmlToElement(content),
    );
  },

  prepend(elem, content) {
    elem.prepend(
      htmlToElement(content),
    );
  },
};

var htmlToElement = (html) => {
  var template = document.createElement('template');
  template.innerHTML = html.trim(); // Never return a text node of whitespace as the result;
  template.content.firstChild.classList.add('-orange');
  return template.content.firstChild;
};

module.exports = insertHtml;
