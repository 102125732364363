var setRideCountHeader = () => {
  var header = document.getElementById('ride-count-header');

  // Determine rides in table by counting rows already there
  var currentRideCount = document.querySelectorAll('tr[id^=ride-]').length;

  if (currentRideCount === 0) {
    /**
     * No Rides Available
     * But a ride is being broadcast
     * Format to single Ride Available State
     */
    header.innerText = '1 Ride Available';
    return;
  }
  /**
   * Multiple Rides Available / 1 Ride available
   * But a ride is being broadcast
   * Increment the count by one
   */
  incrementAndSetHeader(currentRideCount, header, currentRideCount);
};

var incrementAndSetHeader = (currentRideCount, header) => {
  var valueToSet = currentRideCount + 1;
  header.innerText = `${valueToSet} Rides Available`;
};

module.exports = setRideCountHeader;
