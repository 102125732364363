const statusDropdownState = {
  addListeners: (inputs) => {
    inputs.forEach((input) => {
      input.addEventListener('click', (e) => {
        checkInputsState(inputs);
      });
    });
  },
};

const checkInputsState = (inputs) => {
  const checkedOpts = inputs.filter((input) => input.checked === true);
  if (checkedOpts.length === 0) {
    document.getElementById('apply').disabled = true;
  }
};

module.exports = statusDropdownState;
