var setScheduledFilterCount = () => {
  var scheduledFilter = document.getElementById('scheduled-filter');

  // if count is already set find value and increment
  // otherwise add the span and set the value to 1
  if (scheduledFilter.querySelector('span')) {
    var currentValue = parseInt(scheduledFilter.querySelector('span').innerText, 10);

    scheduledFilter.querySelector('span').innerText = currentValue + 1;
  } else {
    var span = document.createElement('span');

    span.classList.add('indicator-circle');
    span.classList.add('-left-spacing');

    span.innerText = 1;

    scheduledFilter.appendChild(span);
  }
};

module.exports = setScheduledFilterCount;
